<template>
  <div class="soon-stick">
    <img class="soon-stick__icon" alt="soon-icon" src="/svg/soon.svg">
  </div>
</template>

<style lang="scss" scoped>
$light-gray: #D5D6D7;
$blue: #4DB6BC;

.soon-stick {
  position: absolute;
  right: 10px;
  bottom: 5px;
  width: 120px;

  &__icon {
    width: 100%;
  }

  @media (max-width: 1600px) {
      width: 100px;
  }

  @media (max-width: 1600px) {
    bottom: 0;
  }

  @media (max-width: 360px) {
    bottom: -2px;
  }
}
</style>
