<template>
    <div class="products__content">
      <h2 class="products__name products__name-title" v-scrollanimation
      :class="productTitle.colorClass">
        {{ productTitle.text }}
      </h2>
      <ul class="products__list">
        <li class="products__item"
        :class="{'dubleItem' : dubleItem && index === 1}"

         v-for="product, index in products"
         :key="index" v-scrollanimation>

         <template v-if="dubleItem && index === 1">
          <div class="products__item-subproducts"
           :class="{'unactive' : !subProduct.isLink}"
          v-for="(subProduct, subIndex) in product.subProducts" :key="subIndex">
            <router-link class="products__link"
           :to="{name: subProduct.path}">
            <div class="products__img">
              <img class="products__img-pic" alt="products-image" :src="subProduct.picture">
            </div>
            <div class="products__right">
              <div class="products__desc">
                <h2 class="products__name"
                :class="subProduct.colorClass" v-html="subProduct.title">
                </h2>
              </div>
              <div class="products__link-text">
                Узнать больше ›
              </div>
            </div>
            <soon-stick  v-if="!subProduct.isLink"></soon-stick>
          </router-link>
          </div>
      </template>

      <!-- Для остальных элементов -->
      <template v-else>
        <router-link class="products__link" :class="{'unactive' : !product.isLink}"
           :to="{name: product.path}">
            <div class="products__img">
              <img class="products__img-pic" alt="products-image" :src="product.picture">
            </div>
            <div class="products__right">
              <div class="products__desc">
                <h2 class="products__name"
                :class="product.colorClass">
                  {{product.title}}
                </h2>
                <p class="products__text">
                  {{product.text}}
                </p>
              </div>
              <div class="products__link-text">
                Узнать больше ›
              </div>
            </div>
            <soon-stick  v-if="!product.isLink"></soon-stick>
          </router-link>
      </template>
        </li>
      </ul>
      <right-background />
    </div>
</template>

<script>
import RightBackground from '@/components/shared/background/RightBg.vue';
import SoonStick from '@/components/shared/SoonStick.vue';

export default {
  props: {
    products: {
      type: Array,
      required: true,
    },
    productTitle: {
      type: String,
      required: true,
    },
    dubleItem: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {};
  },
  components: {
    SoonStick,
    RightBackground,
  },
};
</script>

<style lang="scss" scoped>
$white: #FFFFFF;
$dark-gray: #2B3138;
$light-gray: #C0C1C2;
$purple: #8293E8;
$yellow: #F8A81B;
$sky-blue:#5598CF;
$blue: #4DB6BC;
$coral: #FF7D75;

:deep(.section-background__right) {
  top: initial;
  bottom: 0;
}

.products {
  &__content {
    position: relative;
  }

  &__list {
    display: flex;
    justify-content: space-between;
    flex-wrap: wrap;
  }

  &__item:not(.dubleItem), &__item-subproducts {
    box-shadow: 0px 4px 12px rgba(0, 0, 0, 0.2);
    border-radius: 20px;
    overflow: hidden;
    position: relative;
    background: $dark-gray;
    z-index: 20;
    transition: 0.5s;

    &.unactive {
      .products__link-text {
        display: none;
      }
      .products__link {
        pointer-events: none;
        cursor: default;
      }
    }

    &:hover {
      box-shadow: 0px 8px 12px rgb(130, 147, 232, 0.2);

      .products__text,
      .products__link-text {
        color: $white;
      }
    }
  }

  &__item{
    width: 49%;
    margin: 0 0 20px;
    max-height: 455px;

    @media (max-width: 1104px) {
      max-height: initial;
    }

    @media (max-width: 808px) {
      width: 47%;
    }

    @media (max-width: 660px) {
      width: 100%;
    }

    &-subproducts {
      width: 100%;
      max-height: 189px;
      margin-bottom: 20px;

      &:last-child {
        margin: 0;
      }

      @media (max-width: 2008px) {
        max-height: 146px;
      }

      @media (max-width: 1104px) {
        max-height: 235px;
      }

      @media (max-width: 900px) {
        max-height: 250px;
      }

      @media (max-width: 660px) {
        max-height: 293px;
      }
    }
  }

  &__link {
    color: $white;
    display: flex;
    height: 100%;
    position: relative;

    &.unactive {
      pointer-events: none;
      cursor: default;

      & .products__link-text {
        display: none;
      }
    }

    &-text {
      text-transform: uppercase;
      font-family: 'montserratbold', sans-serif;
      font-size: 12px;
      line-height: 20px;
      color: $purple;

     .unactive & {
      display: none;
      }
    }

    @media (max-width: 1104px) {
      flex-direction: column;

      .products__item-subproducts & {
        flex-direction: row;

        @media (max-width: 900px) {
          flex-direction: column;
        }
      }
    }
  }

  &__name {
    font-family: 'montserratbold', sans-serif;
    font-size: 32px;
    line-height: 40px;

    &:after {
      content: "";
      display: block;
      width: 108px;
      height: 6px;
      position: absolute;
      transition: width 0.3s cubic-bezier(0.51, 0.51, 0.51, 0.51);
      margin-top: 7px;
    }

    &.blue:after {
      background-color: $blue;
    }

    &.yellow:after {
      background-color: $yellow;
    }

    &.purple:after {
      background-color: $purple;
    }

    &-title {
      margin: 0 0 53px;
    }

    @media (max-width: 1333px) {
      font-size: 28px;
      line-height: 34px;
    }

    @media (max-width: 1333px) {
      font-size: 24px;
      line-height: 30px;
    }

    @media (max-width: 770px) {
      font-size: 24px;
    }

    @media (max-width: 660px) {
      font-size: 32px;
    }

    @media (max-width: 450px) {
      font-size: 28px;
      line-height: 33px;
    }

    @media (max-width: 360px) {
      font-size: 24px;
      line-height: 28px;
    }
  }

  &__img {
    display: flex;
    width: 45%;

    .products__item-subproducts & {
      width: 30%;

      @media (max-width: 1333px) {
        width: 25%;
      }

      @media (max-width: 1104px) {
        width: 45%;
      }

      @media (max-width: 900px) {
        width: 100%;
        height: 40%;
      }

      @media (max-width: 660px) {
        height: 45%;
      }
    }

    &-pic {
      width: 100%;
      object-fit: cover;
      transition: all 1s ease;
    }

    @media (max-width: 1275px) {
      width: 35%;
    }

    @media (max-width: 1104px) {
      width: 100%;
      height: 250px;
      border-radius: 20px;
    }

    @media (max-width: 770px) {
      height: 180px;
    }

    @media (max-width: 660px) {
      height: 230px;
    }

    @media (max-width: 450px) {
      height: 150px;
    }
  }

  &__right {
    width: 53%;
    padding: 35px 25px;
    display: flex;
    flex-direction: column;
    justify-content: space-between;

    .products__item-subproducts & {
      padding: 20px 25px;
    }

    @media (max-width: 1333px) {
      padding: 13px 20px;
    }

    @media (max-width: 1275px) {
      width: 65%;
    }

    @media (max-width: 1100px) {
      width: 100%;
      height: 100%;
    }

    @media (max-width: 1104px) {
      width: 100%;
      height: 57%;
    }

    @media (max-width: 450px) {
      .unactive & {
      padding: 30px 25px 45px;
      }
    }
  }

  &__desc {
    display: flex;
    flex-direction: column;
  }

  &__text {
    font-weight: 400;
    font-size: 12px;
    line-height: 18px;
    color: $light-gray;
    padding: 30px 0 35px;
    transition: 0.5s;

    @media (max-width: 475px) {
      font-size: 14px;
      line-height: 19px;
    }
  }
}

.before-enter {
  opacity: 0;
  transform: translateY(100px);
  transition: 1s;
}

.enter {
  opacity: 1;
  transform: translateY(0px);
}
</style>
